



















import Vue from "vue";

enum EventType {
  ViewChange = "view-change",
  PathChange = "path-change",
  Visit = "visit",
}

interface MenuItem {
  eventType: EventType;
  imagePath?: string;
  viewName?: string;
  path?: string;
  url?: string;
  childs?: Array<MenuItem>;
}

export default Vue.extend({
  name: "NavigationBar",
  data(): {
    menuItems: Array<MenuItem>;
  } {
    return {
      menuItems: [
        {
          eventType: EventType.ViewChange,
          imagePath: "/images/buttons/home.png",
          viewName: "HomeView",
        },
        {
          eventType: EventType.PathChange,
          imagePath: "/images/buttons/blog.png",
          path: "/blog/",
        },
      ],
    };
  },
  methods: {
    // ボタンクリックイベントハンドラ
    onClicked(item: MenuItem): void {
      switch (item.eventType) {
        case EventType.ViewChange:
          this.$emit("view-change", item.viewName);
          break;
        case EventType.PathChange:
          this.$emit("path-change", item.path);
          break;
        default:
          console.error("invalid event type");
          throw "invalid event type";
      }
    },
  },
});
