







































import Vue from "vue";

interface LinkItem {
  message: string;
  url: string;
  image: string;
}

export default Vue.extend({
  name: "HomeView",
  data(): {
    linkItems: Array<LinkItem>;
    jprsSiteSeal: string;
  } {
    return {
      linkItems: [
        {
          message: "まったりマビノギ",
          url: "https://mabinogi.nexon.co.jp",
          image:
            "https://static.nexon.co.jp/mabinogi/6th_img/community/fansitelink/468_60orange.gif",
        },
        {
          message: "まったりぷそ",
          url: "https://pso2.jp/players/",
          image: "/images/links/ngs_title.png",
        },
      ],
      jprsSiteSeal: `
        <form action="https://sitesealinfo.pubcert.jprs.jp/SiteSealReference" name="SiteSealReference" method="post"
          target="_blank">
          <!--お客様の証明ページを別ウィンドウにて開きます。-->
          <input type="image" name="Sticker" src="/images/certification/jprs_siteseal_120x50_DV.png" alt="クリックして証明書の内容をご確認ください。"
            oncontextmenu="return false;" />
          <!--ダウンロードしたサイトシール画像のパス名を指定します。-->
          <input type="hidden" name="certificateCn" value="www.suzuha.jp" />
          <input type="hidden" name="certificateClass" value="DV" />
          <!--証明書のコモンネーム及び証明書種別（DVもしくはOV）を指定します。-->
        </form>
      `,
    };
  },
  methods: {
    onBannerClick(item: LinkItem): void {
      window.open(item.url, "_blank");
    },
  },
});
