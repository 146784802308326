
















import NavigationBar from "@/components/NavigationBar.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    NavigationBar,
  },
  data() {
    return {
      copyrightSince: 2018,
      copyrightUntil: -1,
    };
  },
  mounted() {
    this.copyrightUntil = this.getNowYear();
  },
  methods: {
    // 本年取得
    getNowYear(): number {
      const date = new Date();
      let year = date.getFullYear();
      return year;
    },
    // ビュー切り替え
    //
    // 現在のビューと異なるビューが表示されている場合のみ、ビューを切り替える。
    onViewChange(viewName: string): void {
      const route = this.$route;

      if (route.name != viewName) {
        const router = this.$router;
        router.push({ name: viewName });
      }
    },
    // パス切り替え
    onPathChange(path: string): void {
      window.location.href = path;
    },
    // 外部サイト訪問
    onSiteVisit(url: string): void {
      window.open(url, "_blank");
    },
  },
});
